/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Basetheme = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired

        $(".loadmorewrap button").on("click", function() {

            var $button = $(this),
                $container = $('.loadmorewrap'),
                data = {
                    "action": "loadmore_posts",
                    "query": BaseAjax.posts,
                    "page" : BaseAjax.current_page,
                    "max_page" : BaseAjax.max_page
                };

            $.ajax({

                url: BaseAjax.ajax_url,
                data : data,
                type : "POST",
                action: 'loadmore_posts',
                success : function( data ){

                    if ( data ) {

                        $container.before(data);

                        BaseAjax.current_page++;

                        if ( BaseAjax.current_page >= BaseAjax.max_page ) {
                            $container.hide();
                        }

                    } else {
                        $container.hide();
                    }

                }

            });

        });

        $(window).on("load", function() {

            if ($('.galleryflex').length) {
                // init Masonry after all images have loaded
                var $grid = $('.galleryflex');
                $grid.masonry({
                    // options...
                    itemSelector: '.imgwrap',
                    columnWidth: '.imgwrap'
                });
            }

        });

        $('.photoswipe').each( function() {
          var $picwrap     = $(this),
            getItems = function() {
              var items = [];
              $picwrap.find('a').each(function() {
                var $href   = $(this).attr('href'),
                  $title  = $(this).find('figcaption').text(),
                  $size   = $(this).data('size').split('x'),
                  $width  = $size[0],
                  $height = $size[1];

                var item = {
                  src : $href,
                  w   : $width,
                  h   : $height,
                  title : $title
                };

                items.push(item);
              });
              return items;
            };

          var items = getItems();

          var $pswp = $('.pswp')[0];
          $picwrap.on('click', 'a', function(event) {
              event.preventDefault();

              var $index = $(this).data('index');

              var options = {
                  index: $index,
                  bgOpacity: 0.9,
                  showHideOpacity: true
              };

              // Initialize PhotoSwipe
              var gallery = new PhotoSwipe($pswp, PhotoSwipeUI_Default, items, options);

              // globalGallery = gallery;
              // $('body').append('<div class="photoSwipe_innerthumbs"></div>');

              // // Gallery starts closing
              // gallery.listen('close', function () {
              //   $('.photoSwipe_innerthumbs').remove();
              // });

              // $picwrap.find('a').each(function() {
              //   var $thumb   = $(this).data('thumb');
              //   $('<img src="' + $thumb + '" class="img-fluid" />').appendTo("div.photoSwipe_innerthumbs");
              // });

              //Get current active index and add class to thumb just to fade a bit
              // $("div.photoSwipe_innerthumbs img").eq(gallery.getCurrentIndex()).addClass('svifaded');

              gallery.init();

                //Handle the swaping of images
              // $('body').on('click', 'div.photoSwipe_innerthumbs img', function (e) {
              //   // $('div.photoSwipe_innerthumbs img').removeClass("svifaded");
              //   // $(this).addClass('svifaded');
              //   globalGallery.goTo($("div.photoSwipe_innerthumbs img").index($(this)));
              // });

          });

        });

        swiperOpts = {
            loop: true,
            // slidesPerView: 1,
            // slidesPerGroup: 1,
            effect: 'fade',
            spaceBetween: 0,
            speed: 1500,
            // effect: 'flip',
            // touchRatio: 3,
            // followFinger: false,
            // simulateTouch: false,
            // resistance: false,
            // longSwipes: false,
            keyboard: {
                enabled: true,
                onlyInViewport: false,
            },
            autoplay: {
                delay: 5000,
                disableOnInteraction: false
            },
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
                // type: 'fraction',
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            }
        };

        if ($('.swiper-full-container').length) {

            swiper = new Swiper('.swiper-full-container', swiperOpts);

        }

        swiperRefeOpts = {

            slidesPerView: 1,
            // slidesPerGroup: 1,
            spaceBetween: 30,
            speed: 1500,
            resistanceRatio: 0,
            // effect: 'flip',
            // touchRatio: 3,
            // followFinger: false,
            // simulateTouch: false,
            // resistance: false,
            // longSwipes: false,
            keyboard: {
                enabled: true,
                onlyInViewport: false,
            },
            // centeredSlides: true,
            autoplay: {
                delay: 5000,
                disableOnInteraction: false
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            breakpoints: {
                660: {
                    slidesPerView: 2,
                    // slidesPerGroup: 2
                },
                992: {
                    slidesPerView: 3,
                    // slidesPerGroup: 3
                },
            }
        };

        if ($('.swiper-refe-container').length) {

            swiper_refe = new Swiper('.swiper-refe-container', swiperRefeOpts);

        }

        if ($('#apts').length) {

            $('.apt__collapse').on('show.bs.collapse', function () {
                $( '#' + $(this).data('wrap') ).addClass('aptopen');
            });

            $('.apt__collapse').on('hide.bs.collapse', function () {
                $( '#' + $(this).data('wrap') ).removeClass('aptopen');
            });

        }


        $(document).on('click', 'a.anchor', function (event) {

            event.preventDefault();

            hrefval = $.attr(this, 'href');
            $('html, body').animate({
                scrollTop: $(hrefval).offset().top - 40
            }, 800);

            // history.pushState(null, null, hrefval);

        });

        $(document).on('click', '.button--modalopen', function(e) {

            e.preventDefault();

            $('#generalmodal').find('.modal-body').html('<div class="text-center"><div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div></div>');
        });


        $('#generalmodal').on('show.bs.modal', function (event) {

            var button = $(event.relatedTarget),
                actiontype = button.data('action'),
                person_id = button.data('id'),
                nonce = button.data('nonce');

            if (actiontype === 'personcard') {

                $.ajax({
                  type: 'POST',
                  url: BaseAjax.ajax_url,
                  data: {
                    action: 'get_person_card',
                    modaltype: actiontype,
                    nonce: nonce,
                    item_id: person_id
                  },
                  success: function( result ) {
                    var modal = $('#generalmodal');
                    modal.find('.modal-body').html(result);
                  },
                  error: function( result ) {
                    alert("An error occured: " + result.status + " " + result.statusText);
                  }
                });


            }

        });

        $('.groups a').on('mouseover click', function(e) {

            e.preventDefault();

            var group = $(this).data('group');

            $('.groups a.active').removeClass('active');
            $(this).addClass('active');

            $('.activegroup').removeClass('activegroup');
            $('#' + group).addClass('activegroup');

        });

        $(document).on('click', '.type-filter', function(e) {

            var aptTypes = [];

            $("input[name='apttypes[]']:checked").each(function () {
              aptTypes.push($(this).val());
            });

            if ( aptTypes.length === 0 && !$('#filter-type-free').is(':checked') ) {

                $('.apt__row').addClass('show');

            } else if ( $('#filter-type-free').is(':checked') ) {

                console.log('gww');

                $('.apt__row').removeClass('show');

                if ( aptTypes.length === 0 ) {
                    $('.apt__row[data-free="available"]').addClass('show');
                } else {
                    $('.apt__row[data-free="available"]').filter(function (i, e) {
                        return aptTypes.indexOf($(this).attr('data-type')) > -1;
                    }).addClass('show');
                }

            } else {

                $('.apt__row').removeClass('show').filter(function (i, e) {
                    return aptTypes.indexOf($(this).attr('data-type')) > -1;
                }).addClass('show');

            }

        });

        $(document).on('click', '.propertymodalbtn', function(e) {

            var aptName = $(this).attr('data-aptname');
            var property = $(this).attr('data-property');

            $('input[type="hidden"][name="apt_name"]').val(aptName);
            $('input[type="hidden"][name="property_name"]').val(property);

        });

        $(document).on('click', '.hitasmodalbtn', function(e) {

            var primaryFam = $('#chosen-apt-primary').attr('data-family');
            var secondaryFam = $('#chosen-apt-secondary').attr('data-family');
            var $dataFamily = $('*[data-name="perheehdot"]');
            var $inputFamily = $('input[name="perheehdot"]');
            // var $tempFamilyData = $('#family-tempdata-holder');
            // var storedCheckbox = $dataFamily.prop("outerHTML");

            // if ( storedCheckbox === '' ) {
            //     storedCheckbox = $tempFamilyData.attr('data-tempdata');
            // }

            if ( primaryFam !== 'perhe' && secondaryFam !== 'perhe' ) {
                $dataFamily.hide();
                $inputFamily.prop("checked", true);
            } else {
                $dataFamily.show();
                $inputFamily.prop("checked", false);
            }

        });

        $(document).on('click', '.reservebtn', function(e) {

            var reserveType = $(this).attr('data-reservetype');
            var ptyName = $(this).attr('data-property');
            var ptyId = $(this).attr('data-propertyid');
            var aptName = $(this).attr('data-aptname');
            var aptId = $(this).attr('data-aptid');
            var aptType = $(this).attr('data-aptype');

            $('#hitas-selected').fadeIn().addClass('shown');

            $('input[type="hidden"][name="property_name"]').val(ptyName);
            $('input[type="hidden"][name="property_id"]').val(ptyId);

            if ( reserveType === 'primary' ) {
                $('input[type="hidden"][name="apt_primary"]').val(aptName);
                $('input[type="hidden"][name="apt_primary_id"]').val(aptId);
                $('#chosen-apt-primary').html(aptName);
                $('#modal-apt-primary').html(aptName);

                if ( aptType === 'perhe' ) {
                    $('#chosen-apt-primary').attr('data-family', 'perhe');
                    $('input[type="hidden"][name="apt_primary_type"]').val('perhe');
                } else {
                    $('#chosen-apt-primary').attr('data-family', 'nope');
                    $('input[type="hidden"][name="apt_primary_type"]').val('');
                }

            } else if ( reserveType === 'secondary' ) {
                $('input[type="hidden"][name="apt_secondary"]').val(aptName);
                $('input[type="hidden"][name="apt_secondary_id"]').val(aptId);
                $('#chosen-apt-secondary').html(aptName);
                $('#modal-apt-secondary').html(aptName);

                if ( aptType === 'perhe' ) {
                    $('#chosen-apt-secondary').attr('data-family', 'perhe');
                    $('input[type="hidden"][name="apt_secondary_type"]').val('perhe');
                } else {
                    $('#chosen-apt-secondary').attr('data-family', 'nope');
                    $('input[type="hidden"][name="apt_secondary_type"]').val('');
                }
            }

        });

      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Basetheme;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
